import React from 'react'

import './promoBlock.css'

const PromoBlock = ({ featuredText, featuredImage, link }) => (
  <div
    className='promo-block'
  >
    {featuredText.length > 0 &&
      <p>{featuredText}</p>
    }
    {featuredImage.length > 0 &&
      <a href={link+"?ref=thealphadex.com"} target="_blank" rel="noopener noreferrer">
        <img src={featuredImage} alt="" />
      </a>
    }
  </div>
)

export default PromoBlock
