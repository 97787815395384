import React from 'react'

// import './header.css'

const Footer = ({ siteTitle }) => (
  <div id="footer">
    <div
      style={{
        width: '100%',
        padding: '1.45rem 1.0875rem 0',
      }}
    >
      <a href="mailto:info@thealphadex.com" style={{
        textDecoration: 'none',
        padding: '10px',
        display: 'block',
        margin: '0 0 0 auto',
        width: '120px',
        textAlign: 'center',
        fontWeight: 'bold',
        background: '#ff9900',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        color: '#fff'
      }}>Contact Us</a>
    </div>
  </div>
)

export default Footer
