import React from 'react'
import { Link } from 'gatsby'

import logo from '../images/logo.png'

import './header.css'

const Header = ({ siteTitle, menuLinks }) => (
  <div id="header">
    <div
      style={{
        margin: '0 auto',
        maxWidth: 1240
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: '#ff9900',
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <img src={logo} alt="alphadex logo" style={{
            margin: 0,
            marginRight: '10px',
            maxWidth: '40px'
          }} />
          {siteTitle}
        </Link>
      </h1>
      <nav style={{ display: 'flex' }}>
        <ul style={{ display: 'flex', margin: 0 }}>
        {
          menuLinks.map(link =>
            <li key={link.name} style={{ 'listStyleType': 'none' }}>
              <Link to={link.link}>{link.name}</Link>
            </li>)
        }
        </ul>
      </nav>
    </div>
  </div>
)

export default Header
