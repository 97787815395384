import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import ReactTable from "react-table";

import 'react-table/react-table.css'
import './subpage.css'

import Layout from '../components/layout'
import PromoBlock from '../components/promoBlock'

export default ({ data }) => {
  console.log(data);
  const customOptionsFilter = ({filter, onChange}) => {
    var unique = [];
    data.allCoursesJson.edges[0].node.courses.map(( course , index) => {
      if(!unique.includes(course.category)) {
        unique.push(course.category);
      }
      return null
    })
    return(
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
      }}>
        <div className="mobile-show">Category Filter:</div>
        <select
          onChange={e => onChange(e.target.value)}
          style={{  width: '100%'  }}
          value={filter ? filter.value : 'all'}
          >
          <option value='all'>Show All</option>
          {
            unique.sort().map((category , index) => {
              return <option key={index} value={category}>{category}</option>
            })
          }
        </select>
      </div>
    )
  }

  const columns = [{
    expander: true,
    show: false
  }, {
    Header: 'Course',
    accessor: 'node',
    width: 'auto',
    Cell: props => (
      props.original.featured ? (
        <div>
          <span role="img" aria-label="star">⭐️</span> 
          <a href={props.original.link+"?ref=thealphadex.com"} target="_blank" rel="noopener noreferrer">{props.original.course}</a> 
          <span role="img" aria-label="star">⭐️</span>
        </div>
      ) : (
        <a href={props.original.link+"?ref=thealphadex.com"} target="_blank" rel="noopener noreferrer">{props.original.course}</a>
      )
    )
  }, {
    Header: 'Instructor',
    accessor: 'instructor',
    width: 'auto',
    Cell: props => (
      <span><strong className="mobile-show">Instructor: </strong>{props.value}</span>
    )
  }, {
    Header: 'Category',
    accessor: 'category',
    width: 'auto',
    Cell: props => (
      <span><strong className="mobile-show">Category: </strong>{props.value}</span>
    ),
    filterable: true,
    filterMethod: (filter, row) => {
      if (filter.value === "all") {
        return true;
      }
      return row[filter.id] === filter.value;
    },
    Filter: ({ filter, onChange }) => customOptionsFilter({filter, onChange})
  }, {
    Header: 'Price',
    accessor: 'price',
    width: 'auto',
    Cell: props => (
      <span><strong className="mobile-show">Price: </strong>{props.value}</span>
    )
  }, {
    Header: 'Best For',
    accessor: 'bestFor',
    width: 'auto'
  }, {
    Header: '',
    id: 'isFeatured',
    accessor: 'featured',
    show: false
  }]
  
  return (
    <Layout>

      <Helmet>
        <title>AlphaDex | The Best Amazon Courses and Gurus</title>
        <meta name="description" content="AlphaDex is your go to spot for finding the best tools and resources for selling on amazon. These are the best courses and gurus to help learn how to best sell on amazon." />
        <meta name="keywords" content="amazon, selling, courses, gurus" />
      </Helmet>

      <div>
        <Link to={'/'}>Back</Link>
        <h1>Alphadex preferred courses and gurus</h1>

        <ReactTable
          data={data.allCoursesJson.edges[0].node.courses}
          columns={columns}
          showPagination={false}
          sortable={false}
          sorted={[
            {
              id: 'isFeatured',
              desc: true
            }
          ]}
          minRows={0}
          resizable={false}
          defaultPageSize={data.allCoursesJson.edges[0].node.courses.length}
          SubComponent={row => {
            return (
              row.row.isFeatured &&
                <PromoBlock featuredText={row.row.node.promo.featuredText} featuredImage={row.row.node.promo.featuredImage} link={row.row.node.link} />
            );
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return{
              'data-qnt': rowInfo.original.featured,
              onClick: (e) => {
                const { expanded } = state;
                const path = rowInfo.nestingPath[0];
                const diff = { [path]: expanded[path] ? false : true };

                instance.setState({
                  expanded: {
                    ...expanded,
                    ...diff
                  }
                });
              }
            }
          }}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allCoursesJson {
      edges {
        node {
          courses{
            course
            link
            instructor
            category
            price
            bestFor
          }
        }
      }
    }
  }
`